@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:900&display=swap');

.profile-container {
  text-align: center;
}

.profile-container h1 {
  padding-top: 20px;
  color: white;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 100px;
}

.profile-container h2 {
  color: white;
  margin-top: -40px;
  letter-spacing: 2px;
  font-size: 32px;
}

.social-media-icons .git {
  background-color: white;
  border-radius: 5px;
  padding-left: 3px;
  padding-right: 3px;
}

.social-media-icons .instagram {
  background-color: white;
  border-radius: 5px;
  padding-left: 3px;
  padding-right: 3px;
}

.social-media-icons .linkedin {
  background-color: white;
  border-radius: 5px;
  padding-left: 3px;
  padding-right: 3px;
}

.github-icon {
  font-size: 50px;
  color: #404040;
  padding: 20px;
}

.github-icon:hover {
  font-size: 60px;
}

.instagram-icon {
  font-size: 50px;
  color: purple;
  padding: 20px;
}

.instagram-icon:hover {
  font-size: 60px;
}

.linkedin-icon {
  font-size: 50px;
  color: #0e76a8;
  padding: 20px;
}

.linkedin-icon:hover {
  font-size: 60px;
}

@media only screen and (max-width: 650px) {
  .profile-container {
    padding-top: 100px;
  }
}
