* {
  outline: none;
}

body {
  background-color: #000f1a;
  background-size: cover;
  background-image: url("images/mountains.jpg");
  background-attachment: fixed;
}

/* Navigation Menu */

.navMenu {
  background-color: black;
  margin: 0;
  height: 60px;
  line-height: 60px;
  text-align: right;
}

.navMenu a {
  text-decoration: none;
  font-size: 16px;
  font-weight: bold;
  color: white;
  padding: 16px 20px;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.navMenu:hover a {
  opacity: 0.5;
}

.navMenu a:hover {
  opacity: 1;
}

.routes {
  height: 60%;
  width: 70%;
  margin-top: 36px;
  margin-right: auto;
  margin-left: auto;
}

/* Navigation - Responsive */

@media only screen and (max-width: 650px) {
  .navMenu {
    margin-top: 10px;
    margin-right: 40px;
    line-height: 1.5px;
  }
  .navMenu a {
    display: block;
    text-align: center;
    background-color: #001d26;
  }
  .bars, .exit {
    margin-top: 16px;
    font-size: 36px;
    color: white;
  }
  .icons {
    display: block;
  }
  .navMenu {
    text-align: center;
    margin-left: 40px;
    background-color: #001d26;
  }
  .navMenu:hover a, .navMenu a:hover {
    opacity: 1;
  }

  .navMenu a:hover {
    background-color: #003e52;
  }
}

@media only screen and (min-width: 650px) {
  .nav-icon {
    display: none;
  }
}

/* Home Page */

.about {
  background-color: #002d38;
  opacity: 0.94;
  padding: 16px;
  border-radius: 8px;
  color: #f2f2f2;
}

.about h1 {
  color: #f2f2f2;
  text-align: center;
  letter-spacing: 6px;
  text-transform: uppercase;
  font-family: 'Rubik', sans-serif;
}

.about h4 {
  line-height: 1.5;
}

.skills {
  background-color: #002d38;
  opacity: 0.94;
  margin-top: 30px;
  border-radius: 8px;
  padding: 16px;
  color: #f2f2f2;
}

.skills h1 {
  color: #f2f2f2;
  text-align: center;
  letter-spacing: 6px;
  text-transform: uppercase;
  font-family: 'Rubik', sans-serif;
}

.interests {
  background-color: #002d38;
  opacity: 0.94;
  margin-top: 30px;
  margin-bottom: 20px;
  padding: 16px;
  border-radius: 8px;
  color: #f2f2f2;
}

.interests h1 {
  color: #f2f2f2;
  text-align: center;
  letter-spacing: 6px;
  text-transform: uppercase;
  font-family: 'Rubik', sans-serif;
}

.interests h2 {
  padding: 0 80px;
  text-align: center;
  letter-spacing: 2px;
}

.films {
  max-width: 25.6%;
}

.albums {
  max-width: 40%;
}

.coding {
  max-width: 34.4%;
}

.icons {
  text-align: center;
}

.dev-icon {
  display: inline-block;
  text-align: center;
  padding: 0 6% 0 6%;
}

.icons img {
  width: 60px;
  height: 60px;
  background-color: white;
  border-radius: 10px;
}

/* Contact Page */

.contact {
  background-color: #002d38;
  padding: 16px;
  border-radius: 8px;
  margin-bottom: 16px;
  color: #f2f2f2;
}

.contact h1 {
  text-align: center;
  letter-spacing: 4px;
  text-transform: uppercase;
  font-family: 'Rubik', sans-serif;
}

.headshot {
  width: 200px;
  max-width: 100%;
  height: auto;
}

.contact-info {
  margin-top: 20px;
  column-count: 2;
  font-family: 'Rubik', sans-serif;
}

.contact-info h3 {
  max-width: 100%;
  height: auto;
}
