.projects {
  text-align: center;
  padding: 10px;
  margin-top: -10px;
}

.projects h1 {
  text-transform: uppercase;
  letter-spacing: 6px;
  color: white;
  font-size: 40px;
  border-top: 2px solid white;
  border-radius: 12px;
  padding: 12px;
  text-shadow: 2px 2px #001d26;
}

.projects h3 {
  color: white;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.projects button {
  font-size: 14px;
  background-color: #003e52;
  margin-top: 15px;
  margin-left: 60px;
  margin-right: 60px;
  border-radius: 4px;
  border: none;
  box-shadow: 2px 2px #001d26;
}

.projects button:hover {
  background-color: #005975;
  font-size: 16px;
}

.project-box h1 {
  color: #f2f2f2;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 4px;
}

.project-box h2 {
  text-align: center;
}

.project-box h3 {
  text-align: center;
}

.project-box h4 {
  line-height: 1.5;
}

.project-box {
  background-color: #002d38;
  opacity: 0.92;
  padding: 16px;
  border-radius: 8px;
  color: #f2f2f2;
  margin-top: 16px;
  margin-bottom: 16px;
}

.demo {
  text-align: center;
}

.demo iframe {
  border: 4px solid black;
  border-radius: 6px;
  width: 80%;
}

.content-links {
  margin-top: 10px;
}

.content-links a {
  text-decoration: none;
  color: #f2f2f2;
  padding: 16px;
}

.content-links a:hover {
  color: #3297a8;
}

.content {
  background-color: #121212;
  color: #f2f2f2;
  padding: 10px;
  margin-top: 14px;
  margin-left: 40px;
  margin-right: 40px;
  border-radius: 6px;
  border: 1px solid #f2f2f2;
}

.reel2real, .renecades, .itineraryhub, .ihprofile {
  width: 85%;
  height: auto;
}

.ih-images {
  text-align: center;
}

img {
  border-radius: 4px;
}
